import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../store/store';
import EntityListPage from '../../../pages/lists/EntityListPage';
import { useSelector } from 'react-redux';
import { fetchSenatorList, selectSenatorList } from '../store/senateListSlice';
import { entityListExtra } from '../../../interfaces/generic-entity.interface';

export enum senateType {
    Members,
    Cmtes,
}

const SenateListDataLayer = ({ listType }: { listType: senateType }) => {
    const dispatch = useAppDispatch();

    const entity_list: entityListExtra[] | null = useSelector(selectSenatorList);

    useEffect(() => {
        dispatch(fetchSenatorList());
    }, [dispatch]);

    let type: string;
    switch (listType) {
        case senateType.Members:
            type = 'senators';
            break;
        case senateType.Cmtes:
            type = 'senators';
            break;
    }

    return <EntityListPage type={type as any} list={entity_list || []} />;
};

export default SenateListDataLayer;
