import React, { useEffect } from 'react';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import { TempPage } from './pages/misc/TempPage';
import { AboutPage } from './pages/AboutPage';
import Navbar from './components/navbar/Navbar';
import Landing from './pages/Landing';
import Footer from './components/misc/Footer';
import ScrollToTop from './components/misc/ScrollToTop';
import SecurityWrapper from './authentication/SecurityWrapper';
import axios from 'axios';
import { RedirectPage } from './pages/misc/RedirectPage';
import ContactUs from './pages/ContactUs';
import PricingPage from './pages/PricingPage';
import Features from './pages/Features';
import SectorDataLayer from './features/sector-dashboards/data/SectorDataLayer';
import SectorListDataLayer from './features/sector-dashboards/data/SectorListDataLayer';
import InstitutionDataLayer from './features/institution-dashboards/data/InstitutionDataLayer';
import InstitutionListDataLayer from './pages/lists/InstitutionListDataLayer';
import CommitteeListDataLayer from './pages/lists/CommitteeListDataLayer';
import CommitteeDataLayer from './features/committee-dashboards/data/CommitteeDataLayer';
import IssueListDataLayer from './pages/lists/IssueListDataLayer';
import IssueDataLayer from './features/issue-dashboards/data/IssueDataLayer';

import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import ValidateEmailWrapper from './authentication/ValdiateEmailWrapper';
import SelectionsPage from './pages/user/SelectionsPage';
import NewsletterSelection from './pages/user/NewsletterSelection';
import OrganizationDataLayer from './pages/dashboards/OrganizationDataLayer';
import OrganizationListDataLayer from './pages/lists/OrganizationListDataLayer';
import FAQ from './pages/FAQ';
import BillingPage from './pages/BillingPage';
import QueryPage from './pages/QueryPage';
import EnterpriseSettings from './pages/user/EnterpriseSettings';
import RegistrationDataLayer from './components/inhouse-dashboards/RegistrationDataLayer';
import LobbyFirmListDataLayer from './pages/lists/LobbyFirmListDataLayer';
import LobbyFirmDataLayer from './pages/dashboards/LobbyFirmDataLayer';
import AccountPage from './pages/user/AccountPage';
import RequestDemonstrationPage from './pages/RequestDemonstrationPage';
import { InHouse } from './pages/InHouse';
import ConsultantListDataLayer from './pages/lists/ConsultantListDataLayer';
import ConsultantDataLayer from './pages/dashboards/ConsultantDataLayer';
import SignupPage from './pages/SignupPage';
import DPOHListDataLayer from './pages/lists/DPOHListDataLayer';
import DPOHDataLayer from './pages/dashboards/DPOHDataLayer';
import InactiveOrganizationListDataLayer from './pages/lists/InactiveOrganizationListDataLayer';
import InactiveOrganizationDataLayer from './pages/dashboards/InactiveOrganizationDataLayer';

import InitializeGoogleAnalytics, { GATrackPage } from './google_analytics/TrackEvent';
import { useSelector } from 'react-redux';
import { selectEmail, selectUserProductType } from './store/reducers/userSlice';
import SubjectListDataLayer from './pages/lists/SubjectListDataLayer';
import SubjectDataLayer from './features/subject-dashboards/data/SubjectDataLayer';
import GlobalDataLayer from './pages/dashboards/GlobalDataLayer';
import CustomIssuePage from './pages/user/CustomIssuePage';
import SolutionsFor from './pages/SolutionsFor';
import GRInsiderFeatures from './pages/features/GRInsiderFeatures';
import StayAheadFeatures from './pages/features/StayAheadFeatures';
import ResearchFeature from './pages/features/ResearchHub';
import StakeholderFeatures from './pages/features/StakeholderFeatures';
import IssueTrackFeatures from './pages/features/IssueTrackFeatures';
import ParliamentMonitor from './pages/features/ParliamentMonitor';
import TrialPage from './pages/trials/TrialPage';
import EmailTrialPage from './pages/trials/EmailTrialPage';
import MetaTag from './components/metatag-component/MetaTag';
import CommitteeMPListDataLayer from './pages/lists/CommitteeMPListDataLayer';
import SenateListDataLayer, {
    senateType,
} from './features/senate-dashboards/data/SenateListDataLayer';
import SenatorDataLayer from './features/senate-dashboards/data/SenatorDataLayer';

InitializeGoogleAnalytics();
/*
    Component: App.tsx
    Params: N/A
    Author: Will Brewer
    Desc:
        Entrypoint for react app. Handles routing and scroll to top behavior on path change
*/

// Configure Axios defaults across app
axios.defaults.withCredentials = true;
const App = () => {
    const location = useLocation();
    const [open, setOpen] = React.useState(true);
    const userEmail = useSelector(selectEmail);
    const userProductType = useSelector(selectUserProductType);

    useEffect(() => {
        if (userEmail != null && userProductType != null) {
            GATrackPage(location.pathname + location.search, userEmail, userProductType.toString());
        }
    }, [location, userProductType, userEmail]);

    return (
        <div className="App">
            <MetaTag />
            <Navbar />
            <div>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <ScrollToTop />
                    <Snackbar
                        open={open && location.pathname === '/'}
                        onClose={() => setOpen(false)}
                        autoHideDuration={6000}
                        message="This is our Canadian Service"
                    >
                        <Alert onClose={() => setOpen(false)} severity="info">
                            You're using our Canadian services
                        </Alert>
                    </Snackbar>
                    <Routes>
                        <Route path="/redirect" element={<RedirectPage />} />
                        <Route path="/trial/email" element={<EmailTrialPage />} />
                        <Route element={<ValidateEmailWrapper />}>
                            <Route path="/" element={<Landing />} />
                            <Route path="/about" element={<AboutPage />} />
                            <Route path="/contact" element={<ContactUs />} />
                            <Route path="/pricing" element={<PricingPage />} />
                            <Route path="/signup" element={<SignupPage />} />
                            <Route path="/subscription" element={<BillingPage />} />
                            <Route path="/features" element={<Features />} />
                            <Route path="/features/stakeholder" element={<StakeholderFeatures />} />
                            <Route path="/features/research" element={<ResearchFeature />} />
                            <Route path="/features/tracking" element={<IssueTrackFeatures />} />
                            <Route path="/features/monitor" element={<ParliamentMonitor />} />
                            <Route path="/features/updates" element={<StayAheadFeatures />} />
                            <Route path="/features/gr-insider" element={<GRInsiderFeatures />} />
                            <Route path="/solutions" element={<SolutionsFor />} />
                            <Route path="/faq" element={<FAQ />} />
                            <Route path="/trial" element={<TrialPage />} />
                            <Route path="/request" element={<RequestDemonstrationPage />} />

                            <Route path="/sectors" element={<SectorListDataLayer />} />
                            <Route path="/institutions" element={<InstitutionListDataLayer />} />
                            <Route path="/committees" element={<CommitteeListDataLayer />} />
                            <Route
                                path="/senators"
                                element={<SenateListDataLayer listType={senateType.Members} />}
                            />
                            <Route path="/subjects" element={<SubjectListDataLayer />} />
                            <Route path="/issues" element={<IssueListDataLayer />} />
                            <Route path="/firms" element={<LobbyFirmListDataLayer />} />
                            <Route
                                path="/institutions/:entityId/dpoh"
                                element={<DPOHListDataLayer />}
                            />
                            <Route
                                path="/committees/:entityId/mps"
                                element={<CommitteeMPListDataLayer />}
                            />
                            <Route
                                path="/firms/:entityId/consultants"
                                element={<ConsultantListDataLayer />}
                            />

                            <Route path="/big-picture" element={<GlobalDataLayer />} />

                            <Route path="/custom-query" element={<QueryPage />} />

                            <Route element={<SecurityWrapper />}>
                                {/* all routes inside this wrapper are protected by login and subscription */}
                                <Route path="/sectors" element={<SectorListDataLayer />} />
                                <Route
                                    path="/sectors/:entityId/organizations"
                                    element={<OrganizationListDataLayer />}
                                />
                                <Route
                                    path="/sectors/:entityId/organizations/inactive"
                                    element={<InactiveOrganizationListDataLayer />}
                                />
                                <Route
                                    path="/sectors/:entityId/organizations/:orgName"
                                    element={<OrganizationDataLayer />}
                                />
                                <Route
                                    path="/sectors/:entityId/organizations/inactive/:orgName"
                                    element={<InactiveOrganizationDataLayer />}
                                />
                                <Route path="/sectors/:entityId" element={<SectorDataLayer />} />
                                <Route
                                    path="/institutions/:entityId"
                                    element={<InstitutionDataLayer />}
                                />
                                <Route
                                    path="/institutions/:entityId/dpoh/:dpohName"
                                    element={<DPOHDataLayer />}
                                />
                                <Route
                                    path="/members-of-parliament/:mpName"
                                    element={<DPOHDataLayer />}
                                />
                                <Route
                                    path="/committees/:entityId"
                                    element={<CommitteeDataLayer />}
                                />

                                <Route path="/senators/:senatorId" element={<SenatorDataLayer />} />
                                <Route path="/subjects/:entityId" element={<SubjectDataLayer />} />
                                <Route path="/issues/:entityId" element={<IssueDataLayer />} />
                                <Route path="/firms/:firmName" element={<LobbyFirmDataLayer />} />
                                <Route
                                    path="/firms/:entityId/consultants/:consultantName"
                                    element={<ConsultantDataLayer />}
                                />
                                <Route
                                    path="/registrations/:regId"
                                    element={<RegistrationDataLayer />}
                                />
                                <Route path="/in-house" element={<InHouse />} />
                                <Route path="/account" element={<AccountPage />} />
                                <Route path="/account/selections" element={<SelectionsPage />} />
                                <Route path="/account/custom" element={<CustomIssuePage />} />
                                <Route
                                    path="/account/enterprise"
                                    element={<EnterpriseSettings />}
                                />
                                <Route
                                    path="/account/newsletters"
                                    element={<NewsletterSelection />}
                                />
                            </Route>
                            <Route path="*" element={<TempPage text="That page doesn't exist" />} />
                        </Route>
                    </Routes>
                </LocalizationProvider>
            </div>
            <Footer />
        </div>
    );
};

export default App;
