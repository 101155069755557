import { Divider, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { MP_FN } from '../../dpoh-dashboards/constants';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { replace_underscores_capitalize } from '../../../helpers/string_formatters';

const link_tran = 'text-slate-600 hover:text-black';

const DashboardDropDown = ({
    primaryText,
}: {
    primaryText:
        | 'sectors'
        | 'institutions'
        | 'DPOHs'
        | 'senators'
        | 'MPs'
        | 'committees'
        | 'subjects'
        | 'issues'
        | 'organizations'
        | 'inactive organizations'
        | 'firms'
        | 'consultants';
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        const handleScroll = () => {
            handleClose();
        };
        window.addEventListener('scroll', handleScroll);
        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            <button
                className="py-1 px-3 flex flex-row justify-center"
                id="dashboards-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {replace_underscores_capitalize(primaryText)}
                {open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
            </button>
            <Menu
                sx={{
                    '& .MuiMenu-paper': {
                        backgroundColor: 'white',
                        borderWidth: '#ededed',
                        borderColor: 'rgba(168,168,168,1)',
                        borderRadius: '10px 10px 10px 10px',
                        boxShadow: 'rgba(168,168,168,1) 10px 10px 35px -10px',
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock={true}
            >
                <div className={link_tran}>
                    <MenuItem component={Link} to="/sectors">
                        Sectors
                    </MenuItem>
                </div>
                <div className={link_tran}>
                    <MenuItem onClick={handleClose} component={Link} to="/institutions">
                        Institutions
                    </MenuItem>
                </div>
                <div className={link_tran}>
                    <MenuItem onClick={handleClose} component={Link} to="/senators">
                        Senators
                    </MenuItem>
                </div>
                <div className={link_tran}>
                    <MenuItem
                        onClick={handleClose}
                        component={Link}
                        to={`/institutions/103/dpoh?institution=${encodeURIComponent(
                            'House of Commons'
                        )}&title=${encodeURIComponent(MP_FN)}`}
                    >
                        Members of Parliament
                    </MenuItem>
                </div>
                <div className={link_tran}>
                    <MenuItem onClick={handleClose} component={Link} to="/committees">
                        Parliamentary Committees
                    </MenuItem>
                </div>
                <div className={link_tran}>
                    <MenuItem onClick={handleClose} component={Link} to="/subjects">
                        Subjects
                    </MenuItem>
                </div>
                <div className={link_tran}>
                    <MenuItem onClick={handleClose} component={Link} to="/issues">
                        Issues
                    </MenuItem>
                </div>
                <div className={link_tran}>
                    <MenuItem onClick={handleClose} component={Link} to="/firms">
                        Lobby Firms
                    </MenuItem>
                </div>
                <Divider sx={{ marginY: 1 }} />
                <div className={link_tran}>
                    <MenuItem onClick={handleClose} component={Link} to="/in-house">
                        In-House
                    </MenuItem>
                </div>
                <Divider sx={{ marginY: 1 }} />
                <div className={link_tran}>
                    <MenuItem onClick={handleClose} component={Link} to="/big-picture">
                        Big Picture
                    </MenuItem>
                </div>
            </Menu>
        </div>
    );
};

export default DashboardDropDown;
