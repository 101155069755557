import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../../store/store';
import { entityListExtra } from '../../../interfaces/generic-entity.interface';

interface SenateListState {
    list: entityListExtra[] | null;
    loading: boolean;
}

const initialState: SenateListState = {
    list: null,
    loading: false,
};

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const fetchSenatorList = createAsyncThunk(
    'senate/member/fetchSenatorList',
    async (): Promise<entityListExtra[]> => {
        const response = await axios.get(`${baseURL}/senate/member/list`);
        const senators: entityListExtra[] = response.data.senatorList;

        return senators;
    }
);
const senateListSlice = createSlice({
    name: 'issueListReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSenatorList.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchSenatorList.fulfilled, (state, action) => {
                state.loading = false;
                state.list = action.payload;
            })
            .addCase(fetchSenatorList.rejected, (state) => {
                state.loading = false;
                state.list = null;
            });
    },
});

export const selectSenatorList = (state: RootState) => state.senateList.list;
export const selectSenatorListLoading = (state: RootState) => state.senateList.loading;

export default senateListSlice.reducer;
