import axios from 'axios';
import {
    entityLobbyingActivity,
    entityOrg,
    entityOverview,
    entityOverviewRefined,
    entityOverviews,
} from '../../../interfaces/generic-entity.interface';
import { overviewFormatter } from '../../../helpers/overview_formatter';
import moment from 'moment';
import {
    entityActivity,
    entityActivityRaw,
} from '../../../interfaces/organization-entity.interface';
import {
    consultantLinkFormatter,
    lobbyFirmLinkFormatter,
    organizationLinkFormatter,
    sectorLinkFormatter,
} from '../../../helpers/internal_routing';
import { mpBiography } from '../../dpoh-dashboards/interface/dpoh-entity.interface';

// Base URL for accessing the API
const baseURL = process.env.REACT_APP_API_BASE_URL;

// Fetch Senator bio
const fetchSenatorBio = async (senatorId: string, date: string): Promise<mpBiography> => {
    const bio_response = await axios.get(`${baseURL}/senate/member/${senatorId}/bio?date=${date}`);
    const bio_data: mpBiography = bio_response.data;

    return bio_data;
};

// Fetch an overview of the given senator
const fetchSenatorOverview = async (senatorId: string, date: string) => {
    const year = date?.split('-')[0];
    const month = date?.split('-')[1];

    const yearly_response = await axios.get(
        `${baseURL}/senate/member/${encodeURIComponent(senatorId)}/overview?monthly=false`
    );
    const yearly_data: entityOverviewRefined[] = yearly_response.data.overview
        .filter((entry: any) => +entry.year <= +year)
        .map((entry: entityOverview) => {
            return {
                ...entry,
                'Lobbying Reports': entry.count || 0,
                'by Consultants': entry.external || 0,
                Organizations: entry.organizations || 0,
                Sectors: entry.sectors || 0,
            };
        });

    const inverted_yearly = overviewFormatter(yearly_data, [
        'Lobbying Reports',
        'by Consultants',
        'Organizations',
        'Sectors',
    ]);

    const monthly_response = await axios.get(
        `${baseURL}/senate/member/${encodeURIComponent(senatorId)}/overview?monthly=true`
    );

    const monthly_data: entityOverview[] = monthly_response.data.overview
        .filter((entry: any) => {
            return (
                +[entry.year, (entry.month + '').padStart(2, '0')].join('') <=
                +[year, month].join('')
            );
        })
        .slice(0, 18);

    const overview: entityOverviews = {
        yearly: inverted_yearly,
        monthly: monthly_data,
    };

    return overview;
};

// Fetch recent lobbying communications to a given senator
const fetchSenatorRecentLobbyingActivity = async (senatorId: string, date: string) => {
    const response = await axios.get(
        `${baseURL}/senate/member/${encodeURIComponent(senatorId)}/activity?limit=25&date=${date}`
    );
    const data: entityLobbyingActivity[] = response.data.senActivity;
    const converted_data: entityLobbyingActivity[] = data.map((entry) => {
        return {
            ...entry,
            'Prior Comms (36M)': entry.three_years_prior,
            date: moment.utc(entry.date).format('YYYY-MM-DD'),
            organization:
                entry.sector_id && entry.sector
                    ? organizationLinkFormatter(entry.sector_id, entry.sector, entry.client)
                    : entry.client,
            sector:
                entry.sector_id && entry.sector
                    ? sectorLinkFormatter(entry.sector_id, entry.sector)
                    : entry.sector,
            lobby_firm:
                entry.lobby_firm === null || entry.consultant === null
                    ? 'In-House'
                    : lobbyFirmLinkFormatter(entry.lobby_firm),
            consultant:
                entry.lobby_firm === null || entry.consultant === null
                    ? 'In-House'
                    : consultantLinkFormatter(entry.lobby_firm, entry.consultant),
            link: `https://lobbycanada.gc.ca/app/secure/ocl/lrs/do/cmmLgPblcVw?comlogId=${entry.comm}`,
        };
    });
    return converted_data;
};

// Fetch recent lobbying communications to a given DPOH
const fetchSenatorOrgActivity = async (senatorId: string, date: string) => {
    const response = await axios.get(
        `${baseURL}/senate/member/${encodeURIComponent(
            senatorId
        )}/topportionsorg?limit=25&date=${date}`
    );
    const data: entityActivityRaw[] = response.data.senTopPortionsOrg;
    const converted_data: entityActivity[] = data.map((entry) => {
        return {
            ...entry,
            name:
                entry.sector_id && entry.sector
                    ? organizationLinkFormatter(entry.sector_id, entry.sector, entry.name)
                    : entry.name,
            sector:
                entry.sector_id && entry.sector
                    ? sectorLinkFormatter(entry.sector_id, entry.sector)
                    : entry.sector,
            'prior_comms_(12M)': entry.one_year_count,
            'prior_comms_(36M)': entry.three_year_count,
            'percent_comms_(12M)':
                Math.round((entry.one_year_count / entry.one_year_total) * 100 * 100) / 100,
        };
    });
    return converted_data;
};

// Fetch top sectors for a given dpoh
const fetchSenatorTopSectors = async (senatorId: string, date: string) => {
    const response = await axios.get(
        `${baseURL}/senate/member/${encodeURIComponent(senatorId)}/topsectors?date=${date}`
    );

    const data: entityOrg[] = response.data.senTopSectors;
    // Count the total number of comms for sectors outside of the top 6
    var other_count = 0;
    if (data.length > 6)
        other_count = data
            .slice(6)
            .map((item) => item.count)
            .reduce((sum, next) => sum + next);

    const top_six = data.slice(0, 6);
    if (other_count > 0) top_six.push({ name: 'Other', code: 'Other', count: other_count });

    return top_six;
};

// Fetch top sectors for a given dpoh
const fetchSenatorInternalExternalSplit = async (senatorId: string, date: string) => {
    const response = await axios.get(
        `${baseURL}/senate/member/${encodeURIComponent(senatorId)}/firmbreakdown?date=${date}`
    );

    const data: entityOrg[] = response.data.senFirmBreakdown;
    // Count the total number of comms for sectors outside of the top 6
    var other_count = 0;
    if (data.length > 6)
        other_count = data
            .slice(6)
            .map((item) => item.count)
            .reduce((sum, next) => sum + next);

    const top_six = data.slice(0, 6);
    if (other_count > 0) top_six.push({ name: 'Other', code: 'Other', count: other_count });

    return top_six;
};

// Fetch top subjects for a given dpoh
const fetchSenatorTopSubjects = async (senatorId: string, date: string) => {
    const response = await axios.get(
        `${baseURL}/senate/member/${encodeURIComponent(senatorId)}/topsubjects?date=${date}`
    );

    const data: entityOrg[] = response.data.senTopSubjects;
    // Count the total number of comms for sectors outside of the top 6
    var other_count = 0;
    if (data.length > 6)
        other_count = data
            .slice(6)
            .map((item) => item.count)
            .reduce((sum, next) => sum + next);

    const top_six = data.slice(0, 6);
    if (other_count > 0) top_six.push({ name: 'Other', code: 'Other', count: other_count });

    return top_six;
};

export const SenatorAPI = {
    fetchSenatorBio,
    fetchSenatorOverview,
    fetchSenatorRecentLobbyingActivity,
    fetchSenatorOrgActivity,
    fetchSenatorTopSectors,
    fetchSenatorInternalExternalSplit,
    fetchSenatorTopSubjects,
};
