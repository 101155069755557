import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../store/store';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import {
    fetchSenatorBio,
    fetchSenatorInternalExternalSplit,
    fetchSenatorOrgActivity,
    fetchSenatorOverview,
    fetchSenatorRecentLobbyingActivity,
    fetchSenatorTopSectors,
    fetchSenatorTopSubjects,
    selectSenatorBio,
    selectSenatorBioLoading,
    selectSenatorLobbyingActivity,
    selectSenatorLobbyingActivityLoading,
    selectSenatorOrgActivity,
    selectSenatorOrgActivityLoading,
    selectSenatorOverview,
    selectSenatorOverviewLoading,
    selectSenatorTopPartners,
    selectSenatorTopPartnersLoading,
    selectSenatorTopSectors,
    selectSenatorTopSectorsLoading,
    selectSenatorTopSubjects,
    selectSenatorTopSubjectsLoading,
} from '../store/senatorSlice';
import { useSelector } from 'react-redux';
import {
    entityLobbyingActivity,
    entityOrg,
    entityOverviews,
} from '../../../interfaces/generic-entity.interface';
import DPOHDashboard from '../../../pages/dashboards/DPOHDashboard';
import { mpBiography } from '../../dpoh-dashboards/interface/dpoh-entity.interface';
import { entityActivity } from '../../../interfaces/organization-entity.interface';

const SenatorDataLayer = () => {
    const dispatch = useAppDispatch();

    const [date, setDate] = useState<string>(moment.utc(moment()).format('YYYY-MM-DD'));

    // Get the name of the current firms
    // const url_params = useParams();

    // const senatorId: string | undefined = url_params.senatorId ?? '';

    const location = useLocation();

    const senatorName: string = new URLSearchParams(location.search).get('name') || '';

    const overview: entityOverviews | null = useSelector(selectSenatorOverview);
    const overview_loading: boolean = useSelector(selectSenatorOverviewLoading);

    const bio: mpBiography | null = useSelector(selectSenatorBio);
    const bio_loading: boolean | null = useSelector(selectSenatorBioLoading);

    const top_sectors: entityOrg[] | null = useSelector(selectSenatorTopSectors);
    const top_sectors_loading: boolean = useSelector(selectSenatorTopSectorsLoading);

    const top_partners: entityOrg[] | null = useSelector(selectSenatorTopPartners);
    const top_partners_loading: boolean = useSelector(selectSenatorTopPartnersLoading);

    const top_subjects: entityOrg[] | null = useSelector(selectSenatorTopSubjects);
    const top_subjects_loading: boolean = useSelector(selectSenatorTopSubjectsLoading);

    const lobbying_activity: entityLobbyingActivity[] | null = useSelector(
        selectSenatorLobbyingActivity
    );
    const lobbying_activity_loading: boolean = useSelector(selectSenatorLobbyingActivityLoading);

    const org_activity: entityActivity[] | null = useSelector(selectSenatorOrgActivity);
    const org_activity_loading: boolean = useSelector(selectSenatorOrgActivityLoading);

    useEffect(() => {
        dispatch(
            fetchSenatorBio({
                senatorId: senatorName,
                date: date,
            })
        );
        dispatch(
            fetchSenatorOverview({
                senatorId: senatorName,
                date: date,
            })
        );
        dispatch(
            fetchSenatorRecentLobbyingActivity({
                senatorId: senatorName,
                date: date,
            })
        );
        dispatch(
            fetchSenatorOrgActivity({
                senatorId: senatorName,
                date: date,
            })
        );
        dispatch(
            fetchSenatorTopSubjects({
                senatorId: senatorName,
                date: date,
            })
        );
        dispatch(
            fetchSenatorTopSectors({
                senatorId: senatorName,
                date: date,
            })
        );
        dispatch(
            fetchSenatorInternalExternalSplit({
                senatorId: senatorName,
                date: date,
            })
        );
    }, [dispatch, date, senatorName]);

    const overview_headers = ['count'];

    // Get the current year
    const currentYear = moment(date, 'YYYY-MM-DD').year();

    // Populate the list with the last 8 years
    for (let i = currentYear; i >= currentYear - 6; i--) {
        overview_headers.push(String(i));
    }

    return (
        <div>
            <DPOHDashboard
                name={senatorName}
                date={date}
                handleDateChange={setDate}
                overview={overview}
                overviewLoading={overview_loading}
                overviewHeaders={overview_headers}
                overviewIndent={['by Consultants']}
                bio={bio}
                bioLoading={bio_loading}
                bumperData={null}
                bumperDataLoading={false}
                topPartners={top_partners}
                topPartnersLoading={top_partners_loading}
                topSectors={top_sectors}
                topSectorsLoading={top_sectors_loading}
                topSubjects={top_subjects}
                topSubjectsLoading={top_subjects_loading}
                recentActivity={lobbying_activity}
                recentActivityLoading={lobbying_activity_loading}
                orgActivity={org_activity}
                orgActivityLoading={org_activity_loading}
                isMP={false}
                isSenator={true}
                exportLoading={false}
                exportFail={false}
            />
        </div>
    );
};

export default SenatorDataLayer;
