import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import {
    entityOverviews,
    entityLobbyingActivity,
    entityOrg,
} from '../../../interfaces/generic-entity.interface';
import { entityActivity } from '../../../interfaces/organization-entity.interface';
import { SenatorAPI } from '../API/SenateAPI';
import { mpBiography } from '../../dpoh-dashboards/interface/dpoh-entity.interface';

interface SenatorState {
    overview: entityOverviews | null;
    overview_loading: boolean;
    overview_fail: boolean;

    bio: mpBiography | null;
    bio_loading: boolean;
    bio_fail: boolean;

    // sentences: bumperSuperList[] | null;
    // sentences_loading: boolean;
    // sentences_fail: boolean;

    top_sectors: entityOrg[] | null;
    top_sectors_loading: boolean;
    top_sectors_fail: boolean;

    top_partners: entityOrg[] | null;
    top_partners_loading: boolean;
    top_partners_fail: boolean;

    top_subjects: entityOrg[] | null;
    top_subjects_loading: boolean;
    top_subjects_fail: boolean;

    lobbying_activity: entityLobbyingActivity[] | null;
    lobbying_activity_loading: boolean;
    lobbying_activity_fail: boolean;

    org_activity: entityActivity[] | null;
    org_activity_loading: boolean;
    org_activity_fail: boolean;

    // export_loading: boolean;
    // export_fail: boolean;
}

const initialState: SenatorState = {
    overview: null,
    overview_loading: false,
    overview_fail: false,

    // sentences: null,
    // sentences_loading: false,
    // sentences_fail: false,

    bio: null,
    bio_loading: false,
    bio_fail: false,

    top_sectors: null,
    top_sectors_loading: false,
    top_sectors_fail: false,

    top_partners: null,
    top_partners_loading: false,
    top_partners_fail: false,

    top_subjects: null,
    top_subjects_loading: false,
    top_subjects_fail: false,

    lobbying_activity: null,
    lobbying_activity_loading: false,
    lobbying_activity_fail: false,

    org_activity: null,
    org_activity_loading: false,
    org_activity_fail: false,

    // export_loading: false,
    // export_fail: false,
};

// Fetch Senator Biography
export const fetchSenatorBio = createAsyncThunk(
    'senate/member/fetchSenatorBio',
    async ({ senatorId, date }: { senatorId: string; date: string }): Promise<mpBiography> =>
        SenatorAPI.fetchSenatorBio(senatorId, date)
);

// Fetch the yearly overview data for the current Senator
export const fetchSenatorOverview = createAsyncThunk(
    'senate/member/fetchSenatorOverview',
    async ({ senatorId, date }: { senatorId: string; date: string }): Promise<entityOverviews> =>
        SenatorAPI.fetchSenatorOverview(senatorId, date)
);

// Fetch the top lobbying sectors for the current Senator
export const fetchSenatorTopSectors = createAsyncThunk(
    'senate/member/fetchSenatorTopSectors',
    async ({ senatorId, date }: { senatorId: string; date: string }): Promise<entityOrg[]> =>
        SenatorAPI.fetchSenatorTopSectors(senatorId, date)
);

// Fetch the top lobbying partners (firms) for the current DPOH
export const fetchSenatorInternalExternalSplit = createAsyncThunk(
    'senate/member/fetchSenatorTopPartners',
    async ({ senatorId, date }: { senatorId: string; date: string }): Promise<entityOrg[]> =>
        SenatorAPI.fetchSenatorInternalExternalSplit(senatorId, date)
);

// Fetch the top lobbying partners (firms) for the current DPOH
export const fetchSenatorTopSubjects = createAsyncThunk(
    'senate/member/fetchSenatorTopSubjects',
    async ({ senatorId, date }: { senatorId: string; date: string }): Promise<entityOrg[]> =>
        SenatorAPI.fetchSenatorTopSubjects(senatorId, date)
);

// Fetch the recent lobbying activity for the current DPOH
export const fetchSenatorRecentLobbyingActivity = createAsyncThunk(
    'senate/member/fetchSenatorRecentLobbyingActivity',
    async ({
        senatorId,
        date,
    }: {
        senatorId: string;
        date: string;
    }): Promise<entityLobbyingActivity[]> =>
        SenatorAPI.fetchSenatorRecentLobbyingActivity(senatorId, date)
);

// Fetch the organization activity for the current DPOH
export const fetchSenatorOrgActivity = createAsyncThunk(
    'senate/member/fetchSenatorOrgActivity',
    async ({ senatorId, date }: { senatorId: string; date: string }): Promise<entityActivity[]> =>
        SenatorAPI.fetchSenatorOrgActivity(senatorId, date)
);

const senatorSlice = createSlice({
    name: 'senatorReducer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSenatorOverview.pending, (state) => {
                state.overview_loading = true;
            })
            .addCase(fetchSenatorOverview.fulfilled, (state, action) => {
                state.overview_loading = false;
                state.overview_fail = false;
                state.overview = action.payload;
            })
            .addCase(fetchSenatorOverview.rejected, (state) => {
                state.overview_loading = false;
                state.overview_fail = true;
                state.overview = null;
            })
            .addCase(fetchSenatorBio.pending, (state) => {
                state.bio_loading = true;
            })
            .addCase(fetchSenatorBio.fulfilled, (state, action) => {
                state.bio_loading = false;
                state.bio_fail = false;
                state.bio = action.payload;
            })
            .addCase(fetchSenatorBio.rejected, (state) => {
                state.bio_loading = false;
                state.bio_fail = true;
                state.bio = null;
            })
            // .addCase(fetchMPHansSentences.pending, (state) => {
            //     state.sentences_loading = true;
            // })
            // .addCase(fetchMPHansSentences.fulfilled, (state, action) => {
            //     state.sentences_loading = false;
            //     state.sentences_fail = false;
            //     state.sentences = action.payload;
            // })
            // .addCase(fetchMPHansSentences.rejected, (state) => {
            //     state.sentences_loading = false;
            //     state.sentences_fail = true;
            //     state.sentences = null;
            // })
            .addCase(fetchSenatorTopSectors.pending, (state) => {
                state.top_sectors_loading = true;
            })
            .addCase(fetchSenatorTopSectors.fulfilled, (state, action) => {
                state.top_sectors_loading = false;
                state.top_sectors_fail = false;
                state.top_sectors = action.payload;
            })
            .addCase(fetchSenatorTopSectors.rejected, (state) => {
                state.top_sectors_loading = false;
                state.top_sectors_fail = true;
                state.top_sectors = null;
            })
            .addCase(fetchSenatorInternalExternalSplit.pending, (state) => {
                state.top_partners_loading = true;
            })
            .addCase(fetchSenatorInternalExternalSplit.fulfilled, (state, action) => {
                state.top_partners_loading = false;
                state.top_partners_fail = false;
                state.top_partners = action.payload;
            })
            .addCase(fetchSenatorInternalExternalSplit.rejected, (state) => {
                state.top_partners_loading = false;
                state.top_partners_fail = true;
                state.top_partners = null;
            })
            .addCase(fetchSenatorTopSubjects.pending, (state) => {
                state.top_subjects_loading = true;
            })
            .addCase(fetchSenatorTopSubjects.fulfilled, (state, action) => {
                state.top_subjects_loading = false;
                state.top_subjects_fail = false;
                state.top_subjects = action.payload;
            })
            .addCase(fetchSenatorTopSubjects.rejected, (state) => {
                state.top_subjects_loading = false;
                state.top_subjects_fail = true;
                state.top_subjects = null;
            })
            .addCase(fetchSenatorRecentLobbyingActivity.pending, (state) => {
                state.lobbying_activity_loading = true;
            })
            .addCase(fetchSenatorRecentLobbyingActivity.fulfilled, (state, action) => {
                state.lobbying_activity_loading = false;
                state.lobbying_activity_fail = false;
                state.lobbying_activity = action.payload;
            })
            .addCase(fetchSenatorRecentLobbyingActivity.rejected, (state) => {
                state.lobbying_activity_loading = false;
                state.lobbying_activity_fail = true;
                state.lobbying_activity = null;
            })
            .addCase(fetchSenatorOrgActivity.pending, (state) => {
                state.org_activity_loading = true;
            })
            .addCase(fetchSenatorOrgActivity.fulfilled, (state, action) => {
                state.org_activity_loading = false;
                state.org_activity_fail = false;
                state.org_activity = action.payload;
            })
            .addCase(fetchSenatorOrgActivity.rejected, (state) => {
                state.org_activity_loading = false;
                state.org_activity_fail = true;
                state.org_activity = null;
            });
    },
});

export const selectSenatorOverview = (state: RootState) => state.senatorData.overview;
export const selectSenatorOverviewLoading = (state: RootState) =>
    state.senatorData.overview_loading;

export const selectSenatorBio = (state: RootState) => state.senatorData.bio;
export const selectSenatorBioLoading = (state: RootState) => state.senatorData.bio_loading;

// export const selectSenatorSentences = (state: RootState) => state.senatorData.sentences;
// export const selectSenatorSentencesLoading = (state: RootState) =>
//     state.senatorData.sentences_loading;

export const selectSenatorTopSectors = (state: RootState) => state.senatorData.top_sectors;
export const selectSenatorTopSectorsLoading = (state: RootState) =>
    state.senatorData.top_sectors_loading;

export const selectSenatorTopPartners = (state: RootState) => state.senatorData.top_partners;
export const selectSenatorTopPartnersLoading = (state: RootState) =>
    state.senatorData.top_partners_loading;

export const selectSenatorTopSubjects = (state: RootState) => state.senatorData.top_subjects;
export const selectSenatorTopSubjectsLoading = (state: RootState) =>
    state.senatorData.top_subjects_loading;

export const selectSenatorLobbyingActivity = (state: RootState) =>
    state.senatorData.lobbying_activity;
export const selectSenatorLobbyingActivityLoading = (state: RootState) =>
    state.senatorData.lobbying_activity_loading;

export const selectSenatorOrgActivity = (state: RootState) => state.senatorData.org_activity;
export const selectSenatorOrgActivityLoading = (state: RootState) =>
    state.senatorData.org_activity_loading;

export default senatorSlice.reducer;
