import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import userSlice from './reducers/userSlice';
import sectorListSlice from '../features/sector-dashboards/store/sectorListSlice';
import sectorSlice from '../features/sector-dashboards/store/sectorSlice';
import institutionListSlice from './reducers/institutionListSlice';
import institutionSlice from './reducers/institutionSlice';
import committeeListSlice from '../features/committee-dashboards/data/committeeListSlice';
import committeeSlice from '../features/committee-dashboards/store/committeeSlice';
import issueListSlice from '../features/issue-dashboards/store/issueListSlice';
import issueSlice from '../features/issue-dashboards/store/issueSlice';
import organizationSlice from './reducers/organizationSlice';
import customSelectionSlice from './reducers/customSelectionSlice';
import querySlice from './reducers/querySlice';
import lobbyFirmSlice from './reducers/lobbyFirmSlice';
import lobbyFirmListSlice from './reducers/lobbyFirmListSlice';
import RegistrationSlice from './reducers/RegistrationSlice';
import consultantSlice from './reducers/consultantSlice';
import DPOHSlice from '../features/dpoh-dashboards/store/DPOHSlice';
import subjectListSlice from '../features/subject-dashboards/store/subjectListSlice';
import subjectSlice from '../features/subject-dashboards/store/subjectSlice';
import globalSlice from './reducers/globalSlice';
import senateListSlice from '../features/senate-dashboards/store/senateListSlice';
import senatorSlice from '../features/senate-dashboards/store/senatorSlice';

const store = configureStore({
    reducer: {
        user: userSlice,
        sectorList: sectorListSlice,
        institutionList: institutionListSlice,
        committeeList: committeeListSlice,
        issueList: issueListSlice,
        firmList: lobbyFirmListSlice,
        subjectList: subjectListSlice,
        globalData: globalSlice,
        sectorData: sectorSlice,
        institutionData: institutionSlice,
        DPOHData: DPOHSlice,
        committeeData: committeeSlice,
        subjectData: subjectSlice,
        issueData: issueSlice,
        consultantData: consultantSlice,
        lobbyFirmData: lobbyFirmSlice,
        registrationData: RegistrationSlice,
        organizationData: organizationSlice,
        customSelections: customSelectionSlice,
        senateList: senateListSlice,
        senatorData: senatorSlice,
        query: querySlice,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => typeof store.dispatch = useDispatch;

export default store;
